import { goto } from '$app/navigation'
import type { ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import { writable } from 'svelte/store'

import type { IStorageIntegration } from '@routes/storage-integrations/storage-integrations-store'
import { FetchError, handleFetch } from '@utils'

enum LabelingType {
  SingleLabelClassification = 'SingleLabelClassification',
  ObjectDetection = 'ObjectDetection',
  SpeechToText = 'SpeechToText',
  // TODO: add more types after backend update
}

export const labelingTypesValues = Object.values(LabelingType)
export const displayLabelingTypes = {
  [LabelingType.SingleLabelClassification]: 'Single Label Classification',
  [LabelingType.ObjectDetection]: 'Object Detection',
  [LabelingType.SpeechToText]: 'Speech to Text',
}

enum DatasetMetadataType {
  HirundoCSV = 'HirundoCSV',
  COCO = 'COCO',
  YOLO = 'YOLO',
}

export const datasetMetadataTypesValues = Object.values(DatasetMetadataType)
export const displayMetadataTypes = {
  [DatasetMetadataType.HirundoCSV]: 'Hirundo CSV',
  [DatasetMetadataType.COCO]: 'COCO',
  [DatasetMetadataType.YOLO]: 'YOLO',
}

export class HirundoCSV {
  public type: DatasetMetadataType.HirundoCSV = DatasetMetadataType.HirundoCSV
  constructor(public csvUrl: string) {}
}

export class COCO {
  public type: DatasetMetadataType.COCO = DatasetMetadataType.COCO
  constructor(public jsonUrl: string) {}
}

export class YOLO {
  public type: DatasetMetadataType.YOLO = DatasetMetadataType.YOLO
  constructor(
    public dataYamlUrl: string,
    public labelsDirUrl: string,
  ) {}
}

export const isHirundoCSV = (
  labelingInfo?: ILabelingInfo,
  metadataType?: DatasetMetadataType,
): labelingInfo is HirundoCSV => {
  return metadataType == DatasetMetadataType.HirundoCSV
}

export const isCOCO = (
  labelingInfo?: ILabelingInfo,
  metadataType?: DatasetMetadataType,
): labelingInfo is COCO => {
  return metadataType == DatasetMetadataType.COCO
}

export const isYOLO = (
  labelingInfo?: ILabelingInfo,
  metadataType?: DatasetMetadataType,
): labelingInfo is YOLO => {
  return metadataType == DatasetMetadataType.YOLO
}

type ILabelingInfo = HirundoCSV | COCO | YOLO

// TODO: refactor fields after backend update
interface IOptimizationDataset {
  id: number
  name: string
  labelingType: LabelingType
  language?: string
  organizationId?: number
  storageIntegration: IStorageIntegration
  classes: string[] | null
  labelingInfo: ILabelingInfo
  createdAt: Date
  updatedAt: Date
  creatorId: number
  creatorName?: string
  datasetMetadataType: DatasetMetadataType
}

type IRawOptimizationDataset = Omit<IOptimizationDataset, 'createdAt' | 'updatedAt'> & {
  createdAt: string
  updatedAt: string
}

const optimizationDatasetStore = writable(new Map<number, IOptimizationDataset>())

optimizationDatasetStore.subscribe((datasets) => {
  console.log('dataset updated: ', datasets)
})

const parseOptimizationDatasets = (rawDatasets: IRawOptimizationDataset): IOptimizationDataset => {
  const {
    id,
    name,
    labelingType,
    language,
    organizationId,
    classes,
    labelingInfo,
    createdAt,
    updatedAt,
    creatorId,
    creatorName,
    storageIntegration,
    datasetMetadataType,
  } = rawDatasets

  return {
    id,
    name,
    labelingType,
    language,
    organizationId,
    classes,
    labelingInfo,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    creatorId,
    creatorName,
    storageIntegration,
    datasetMetadataType,
  }
}

const optimizationDatasetAsyncState = writable({
  loading: false,
  error: '',
})

const loadOptimizationDatasets = async () => {
  optimizationDatasetAsyncState.set({
    loading: true,
    error: '',
  })
  try {
    const optimizations: IRawOptimizationDataset[] = camelcaseKeys(
      await (await handleFetch('/dataset-optimization/dataset/')).json(),
      { deep: true },
    )
    const optimizationMap = optimizations.reduce((acc, optimization) => {
      acc.set(optimization.id, parseOptimizationDatasets(optimization))
      return acc
    }, new Map<number, IOptimizationDataset>())
    optimizationDatasetStore.set(optimizationMap)
    optimizationDatasetAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load optimizations: ', err)
    optimizationDatasetAsyncState.set({
      loading: false,
      error: 'Failed to load optimizations',
    })
  }
}

const runOptimization = async (
  datasetId: number,
  toastStore: ToastStore,
  optimizationName: string | undefined,
) => {
  try {
    await handleFetch(`/dataset-optimization/run/${datasetId}`, {
      method: 'POST',
    })
    toastStore.trigger({
      message: `Started optimization run for dataset named <strong>${optimizationName}<strong>`,
      background: 'variant-filled-success',
    })
    goto(`/dataset-optimization-runs`)
  } catch (err) {
    console.error(err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to launch optimization run for dataset with error: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      return
    }
    toastStore.trigger({
      message: 'Failed to launch optimization run for dataset',
      background: 'variant-filled-error',
    })
  }
}

export type { IOptimizationDataset }
export {
  DatasetMetadataType,
  LabelingType,
  loadOptimizationDatasets,
  optimizationDatasetAsyncState,
  optimizationDatasetStore,
  parseOptimizationDatasets,
  runOptimization,
}
