import type { ModalSettings, ModalStore, ToastStore } from '@skeletonlabs/skeleton'
import camelcaseKeys from 'camelcase-keys'
import { get, writable } from 'svelte/store'

import { FetchError, handleFetch } from '@utils'

enum StorageType {
  S3 = 'S3',
  GCP = 'GCP',
  Git = 'Git',
}

export const storageTypesValues = Object.values(StorageType)

interface IStorageIntegration {
  id: number
  name: string
  type: StorageType
  createdAt: Date
  updatedAt: Date
  organizationId?: number
  s3?: IS3Integration
  gcp?: IGCPIntegration
  git?: IGitIntegration
  creatorId: number
  creatorName: string
}

interface IS3Integration {
  endpointUrl?: string
  bucketUrl: string
  regionName: string
  accessKeyId: string
  secretAccessKey: string
}

interface IGCPIntegration {
  bucketName: string
  project: string
  credentialsJson: string
}

interface IGitIntegration {
  repo: {
    repositoryUrl: string
    plainAuth?: {
      username: string
      password: string
    }
    sshAuth?: {
      sshKey: string
      sshPassword: string
    }
  }
  branch: string
}

export function isS3Integration(
  integration: unknown,
  type?: StorageType | null,
): integration is IS3Integration {
  return StorageType.S3 == type
}

export function isS3IntegrationField(
  integration: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IS3Integration {
  return isS3Integration(integration, type) && field in integration
}

export function isGCPIntegration(
  integration: unknown,
  type?: StorageType | null,
): integration is IGCPIntegration {
  return StorageType.GCP == type
}

export function isGCPIntegrationField(
  integration: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IGCPIntegration {
  return isGCPIntegration(integration, type) && field in integration
}

export function isGitIntegration(
  integration: unknown,
  type?: StorageType | null,
): integration is IGitIntegration {
  return StorageType.Git == type
}

export function isGitIntegrationField(
  integration: unknown,
  type: StorageType | null,
  field: string,
): field is keyof IGitIntegration {
  return isGitIntegration(integration, type) && field in integration
}

function isValidName(name: unknown): name is string {
  return typeof name === 'string' && name.trim() !== ''
}

function isValidType(type: unknown): type is StorageType {
  return typeof type === 'string' && storageTypesValues.includes(type as StorageType)
}

function isValidOrganizationId(organizationId: unknown): organizationId is number {
  return typeof organizationId === 'number' && organizationId > 0
}

interface IGetPrefix {
  storageInputValues: Record<string, unknown>
  selectedIntegration: IStorageIntegration | null
  type?: StorageType | null
}
export const getStoragePrefix = ({ storageInputValues, selectedIntegration, type }: IGetPrefix) => {
  if (isGCPIntegration(selectedIntegration?.gcp, type)) {
    if (selectedIntegration) {
      return `gcs://${selectedIntegration.gcp.bucketName}/`
    } else {
      return `gcs://${storageInputValues.bucketName || 'unknown'}/`
    }
  } else if (isS3Integration(selectedIntegration?.s3, type)) {
    if (selectedIntegration) {
      return `${selectedIntegration.s3.bucketUrl}/`
    } else {
      return `${!!storageInputValues.bucketUrl && storageInputValues.bucketUrl != 's3://' ? storageInputValues.bucketUrl : 's3://unknown'}/`
    }
  } else if (isGitIntegration(selectedIntegration?.git, type)) {
    if (selectedIntegration) {
      return `${selectedIntegration.git.repo.repositoryUrl}/`
    } else {
      return `${!!storageInputValues.repositoryUrl && storageInputValues.repositoryUrl != 'https://' ? storageInputValues.repositoryUrl : 'https://unknown'}/`
    }
  } else {
    return 'unknown://'
  }
}

const storageIntegrationAsyncState = writable({
  loading: false,
  error: '',
})

const storageIntegrationStore = writable(new Map<number, IStorageIntegration>())

storageIntegrationStore.subscribe((integrations) => {
  console.log('Storage integrations updated: ', integrations)
})

const parseIntegrations = (
  rawIntegrations: Omit<IStorageIntegration, 'createdAt' | 'updatedAt'> & {
    createdAt: string
    updatedAt: string
  },
): IStorageIntegration => {
  const {
    id,
    name,
    type,
    createdAt,
    updatedAt,
    organizationId,
    s3,
    gcp,
    git,
    creatorId,
    creatorName,
  } = rawIntegrations

  return {
    id,
    name,
    type,
    createdAt: new Date(Date.parse(createdAt)),
    updatedAt: new Date(Date.parse(updatedAt)),
    organizationId,
    s3,
    gcp,
    git,
    creatorId,
    creatorName,
  }
}

const loadStorageIntegrations = async () => {
  storageIntegrationAsyncState.set({
    loading: true,
    error: '',
  })
  type IRawStorageIntegration = IStorageIntegration & { createdAt: string; updatedAt: string }
  try {
    const storageIntegrations: IRawStorageIntegration[] = camelcaseKeys(
      await (await handleFetch('/storage-integration/')).json(),
      { deep: true },
    )
    const storageIntegrationMap = storageIntegrations.reduce((acc, integration) => {
      acc.set(integration.id, parseIntegrations(integration))
      return acc
    }, new Map<number, IStorageIntegration>())
    storageIntegrationStore.set(storageIntegrationMap)
    storageIntegrationAsyncState.set({
      loading: false,
      error: '',
    })
  } catch (err) {
    console.error('Failed to load storage integrations: ', err)
    storageIntegrationAsyncState.set({
      loading: false,
      error: 'Failed to load storage integrations',
    })
  }
}

interface IField {
  fieldName: string
  label: string
  type: string
  required?: boolean
  pattern?: RegExp
}

export const storageFields = {
  [StorageType.S3]: [
    { fieldName: 'endpointUrl', label: 'Endpoint URL', type: 'url' },
    {
      fieldName: 'bucketUrl',
      label: '*Bucket URL',
      type: 'text',
      required: true,
      pattern: /^[a-z0-9.-]{3,64}[/]?$/,
    },
    { fieldName: 'regionName', label: '*Region name', type: 'text', required: true },
    { fieldName: 'accessKeyId', label: '*Access key ID', type: 'text', required: true },
    { fieldName: 'secretAccessKey', label: '*Secret access key', type: 'text', required: true },
  ],
  [StorageType.GCP]: [
    { fieldName: 'bucketName', label: '*Bucket name', type: 'text', required: true },
    { fieldName: 'project', label: '*Project', type: 'text', required: true },
    { fieldName: 'credentialsJson', label: '*Credentials JSON', type: 'file', required: true },
  ],
  [StorageType.Git]: [
    { fieldName: 'repositoryUrl', label: '*Repository URL', type: 'url', required: true },
    { fieldName: 'plainAuthUsername', label: 'Username', type: 'text' },
    { fieldName: 'plainAuthPassword', label: 'Password', type: 'password' },
    { fieldName: 'sshAuthKey', label: 'SSH key', type: 'text' },
    { fieldName: 'sshPassword', label: 'SSH password', type: 'password' },
    { fieldName: 'branch', label: '*Branch', type: 'text', required: true },
  ],
}

const getPattern = (field: IField) => {
  return field.pattern?.source
}

const createStorageIntegration = async (
  payload: Record<string, unknown>,
  toastStore: ToastStore,
  creatorId: number,
  creatorName: string,
) => {
  try {
    const response = await handleFetch(`/storage-integration/`, {
      method: 'POST',
      body: payload,
    })

    const rawStorageIntegration = camelcaseKeys(await response.json())
    storageIntegrationStore.update(($storageIntegrationStore) => {
      if (!isValidName(payload.name)) {
        toastStore.trigger({
          message: 'Integration payload is missing a valid name',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }

      if (!isValidType(payload.type)) {
        toastStore.trigger({
          message: 'Integration payload is missing a valid type',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }

      if (!isValidOrganizationId(payload.organizationId)) {
        toastStore.trigger({
          message: 'Integration payload is missing a valid organization ID',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }

      const { name, type, organizationId } = payload

      let s3: IS3Integration | undefined
      let gcp: IGCPIntegration | undefined
      let git: IGitIntegration | undefined

      if (isS3Integration(payload.s3, type)) {
        s3 = payload.s3
      } else if (isGCPIntegration(payload.gcp, type)) {
        gcp = payload.gcp
      } else if (isGitIntegration(payload.git, type)) {
        git = payload.git
      } else {
        toastStore.trigger({
          message: 'Integration payload type is unrecognized',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }
      $storageIntegrationStore.set(
        rawStorageIntegration.id,
        parseIntegrations({
          id: rawStorageIntegration.id,
          name,
          type,
          organizationId,
          s3,
          gcp,
          git,
          createdAt: rawStorageIntegration.createdAt,
          updatedAt: rawStorageIntegration.updatedAt,
          creatorId,
          creatorName,
        }),
      )
      return $storageIntegrationStore
    })
    toastStore.trigger({
      message: `Created storage integration named <strong>${payload.name}</strong>`,
      background: 'variant-filled-success',
    })

    return rawStorageIntegration // return the integration if needed for further use
  } catch (err) {
    console.error(err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to create storage integration: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      return
    }
    toastStore.trigger({
      message: 'Failed to create storage integration',
      background: 'variant-filled-error',
    })
  }
}

const openCreateIntegrationModal = (
  modalStore: ModalStore,
  toastStore: ToastStore,
  creatorId: number,
  creatorName: string,
) => {
  const modal: ModalSettings = {
    type: 'component',
    component: 'integrationFormModal',
    title: 'Create storage integration',
    body: 'Please fill in the details to create a new integration.',
    buttonTextSubmit: 'Create',
    meta: {},
    response: async (response) => {
      if (!response) {
        return
      }
      const { payload, closeModal } = response
      await createStorageIntegration(payload, toastStore, creatorId, creatorName)
      closeModal()
    },
  }
  modalStore.trigger(modal)
}

const editIntegration = async (
  payload: Record<string, unknown>,
  integrationId: number,
  toastStore: ToastStore,
) => {
  try {
    const response = await handleFetch(`/storage-integration/${integrationId}`, {
      method: 'PUT',
      body: payload,
    })
    const rawStorageIntegration = camelcaseKeys(await response.json())
    storageIntegrationStore.update(($storageIntegrationStore) => {
      const existingStorageIntegration = $storageIntegrationStore.get(integrationId)
      if (!existingStorageIntegration) {
        console.error('Existing storage integration not found')
        return $storageIntegrationStore
      }
      const { id, type, createdAt, creatorId, creatorName } = existingStorageIntegration

      if (!isValidName(payload.name)) {
        toastStore.trigger({
          message: 'Integration payload is missing a valid name',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }

      if (!isValidOrganizationId(payload.organizationId)) {
        toastStore.trigger({
          message: 'Integration payload is missing a valid organization ID',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }

      const { name, organizationId } = payload

      let s3: IS3Integration | undefined
      let gcp: IGCPIntegration | undefined
      let git: IGitIntegration | undefined

      if (isS3Integration(payload.s3, type)) {
        s3 = payload.s3
      } else if (isGCPIntegration(payload.gcp, type)) {
        gcp = payload.gcp
      } else if (isGitIntegration(payload.git, type)) {
        git = payload.git
      } else {
        toastStore.trigger({
          message: 'Integration payload type is unrecognized',
          background: 'variant-filled-error',
        })
        return $storageIntegrationStore
      }

      $storageIntegrationStore.set(
        integrationId,
        parseIntegrations({
          id,
          name,
          type,
          organizationId,
          s3,
          gcp,
          git,
          createdAt: createdAt.toString(),
          updatedAt: rawStorageIntegration.updatedAt,
          creatorId,
          creatorName,
        }),
      )
      return $storageIntegrationStore
    })
    toastStore.trigger({
      message: `Updated storage integration named <strong>${payload.name}</strong>`,
      background: 'variant-filled-success',
    })

    return rawStorageIntegration
  } catch (err) {
    console.error('Failed to edit storage integration', err)
    if (err instanceof FetchError && err.message) {
      toastStore.trigger({
        message: `Failed to edit storage integration: ${err.message}`,
        background: 'variant-filled-error',
        autohide: false,
      })
      return
    }
    toastStore.trigger({
      message: 'Failed to edit storage integration',
      background: 'variant-filled-error',
    })
  }
}

const openEditIntegrationModal =
  (modalStore: ModalStore, toastStore: ToastStore, integrationId: number) => () => {
    const integrationToEdit = get(storageIntegrationStore).get(integrationId)
    if (!integrationToEdit) {
      console.error('Integration not found')
      return
    }

    const modal: ModalSettings = {
      type: 'component',
      component: 'integrationFormModal',
      title: 'Edit storage integration',
      body: 'Please update the integration details.',
      buttonTextSubmit: 'Save',
      meta: { integrationId },
      value: integrationToEdit,
      response: async (response) => {
        if (!response) {
          return
        }
        const { payload, closeModal } = response
        await editIntegration(payload, integrationId, toastStore)
        closeModal()
      },
    }
    modalStore.trigger(modal)
  }

const prepareInputValuesForQuery = (
  inputValues: Record<string, unknown>,
  selectedStorageType: StorageType,
): Record<string, unknown> => {
  const payload: Record<string, unknown> = {}

  if (isS3Integration(inputValues, selectedStorageType)) {
    const {
      accessKeyId = '',
      secretAccessKey = '',
      regionName = '',
      bucketUrl = '',
      endpointUrl = null,
    } = inputValues

    const editedEndpointUrl = endpointUrl === '' ? null : endpointUrl

    payload.s3 = {
      accessKeyId,
      secretAccessKey,
      regionName,
      bucketUrl,
      endpointUrl: editedEndpointUrl,
    }
  } else if (
    isGCPIntegration(inputValues, selectedStorageType) &&
    selectedStorageType === StorageType.GCP
  ) {
    const { bucketName, project, credentialsJson } = inputValues

    payload.gcp = {
      bucketName,
      project,
      credentialsJson,
    }
  } else if (
    isGitIntegration(inputValues, selectedStorageType) &&
    selectedStorageType === StorageType.Git
  ) {
    // TODO: Add git fields
  }

  return payload
}

export type { IGCPIntegration, IGitIntegration, IS3Integration, IStorageIntegration }
export {
  createStorageIntegration,
  getPattern,
  loadStorageIntegrations,
  openCreateIntegrationModal,
  openEditIntegrationModal,
  parseIntegrations,
  prepareInputValuesForQuery,
  storageIntegrationAsyncState,
  storageIntegrationStore,
  StorageType,
}
