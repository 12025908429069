import { goto } from '$app/navigation'
import { type PopupSettings, type ToastStore } from '@skeletonlabs/skeleton'
import snakecaseKeys from 'snakecase-keys'
import { get } from 'svelte/store'

import {
  loadOptimizationDatasets,
  optimizationDatasetStore,
} from './routes/optimization-datasets/optimization-datasets-store'
import { loadOrganizations, organizationsStore } from './routes/organizations/organizations-store'
import {
  loadStorageIntegrations,
  storageIntegrationStore,
} from './routes/storage-integrations/storage-integrations-store'
import { loggedInStore, User } from './stores/logged-in-store'
import { loadUsers, usersStore } from './stores/user-store'
import { Role } from '@routes/user-associations/user-association-store'

export class FetchError extends Error {
  constructor(
    public statusCode: number,
    public reason: string,
  ) {
    super(reason)
    this.statusCode = statusCode
    this.name = 'FetchError'
  }
}

export interface Config {
  apiBaseUrl: string
  clientBaseUrl: string
  googleIdentityPlatform?: {
    apiKey: string
    authDomain: string
  }
  postHogId?: string
	clearmlAddress?: string
  clearmlProjectId?: string
  airGapped?: boolean
}

let config: Config | null = null

export const getConfig = async (toastStore?: ToastStore): Promise<Config> => {
  if (config) {
    return config
  }
  const result = await (await handleFetch('config/config.json', {}, '', toastStore)).json()
  config = result
  return result
}

export const airGappedUserPasswordLocalStorage = 'airGappedUserPassword'
export const setAirGappedUserPassword = (username: string, password: string) => {
  localStorage.setItem(airGappedUserPasswordLocalStorage, btoa(`${username}:${password}`))
}

let lastCsrfToken = ''
let isAirGapped: boolean
let airGappedUserPassword: string

export const handleFetch = async <T extends string>(
  path: string,
  options: Omit<RequestInit, 'body'> & { body?: Partial<Record<T, unknown>> } = {},
  host?: string,
  toastStore?: ToastStore,
  retry = 0,
  sse = false,
): Promise<Response> => {
  if (retry > 3) {
    throw new FetchError(500, 'Failed to fetch data. Exceeded retry limit.')
  }
  if (host === undefined) {
    const config = await getConfig(toastStore)
    host = config.apiBaseUrl
    if (isAirGapped === undefined) {
      isAirGapped = config.airGapped ?? false
      airGappedUserPassword = localStorage.getItem(airGappedUserPasswordLocalStorage) ?? ''
    }
  }
  const useCsrf = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(options.method ?? '')
  if (useCsrf && lastCsrfToken === '') {
    // ⬆️ Get a new CSRF token if we don't have one yet
    lastCsrfToken =
      (
        await fetch(`${host}/csrf`, {
          credentials: 'include',
        })
      ).headers.get('X-CSRF-Token') || ''
  }
  if (sse) {
    const eventSource = new EventSource(`${host}${path}`, {
      withCredentials: true,
    })
    const event: MessageEvent = await new Promise((resolve, reject) => {
      eventSource.onmessage = (event) => {
        resolve(event)
      }
      eventSource.onerror = (event) => {
        reject(event)
      }
    })
    eventSource.close()
    const response = new Response(event.data)
    return response
  }
  const response = await fetch(`${host}${path}`, {
    credentials: 'include',
    ...options,
    body: options.body ? JSON.stringify(snakecaseKeys(options.body)) : undefined,
    headers: {
      ...(options.body ? { 'Content-Type': 'application/json' } : {}),
      ...options.headers,
      ...(useCsrf ? { 'X-CSRF-Token': lastCsrfToken } : {}),
      ...(isAirGapped ? { Authorization: `Basic ${airGappedUserPassword}` } : {}),
    },
  })
  if (response.headers.has('X-CSRF-Token')) {
    // ⬆️ If the response has a new CSRF token, save it. This uses existing GET requests to store a CSRF
    lastCsrfToken = response.headers.get('X-CSRF-Token') || ''
  }
  if (useCsrf) {
    // ⬆️ If we used the CSRF token, clear it. It is no longer valid
    lastCsrfToken = ''
  }
  if (toastStore && response.headers.has('X-License-Status')) {
    let productString = ''
    let expiryString = ''
    if (response.headers.has('X-License-Product')) {
      productString = ` for ${response.headers.get('X-License-Product')} `
    }
    if (response.headers.has('X-License-Expires-In')) {
      expiryString = ` Your license expires in ${response.headers.get('X-License-Expires-In')}. `
    }
    if (response.headers.get('X-License-Status') === 'EXPIRED') {
      toastStore.trigger({
        message: `Your license ${productString}has expired. Please contact Hirundo to renew your license. Until you do so, no new optimization runs can be started.`,
        background: 'variant-filled-error',
      })
    } else if (response.headers.get('X-License-Status') === 'INVALID') {
      toastStore.trigger({
        message: `Your license ${productString}is invalid. Please contact Hirundo to obtain a valid license. Until you do so, no new optimization runs can be started.`,
        background: 'variant-filled-error',
      })
    } else if (response.headers.get('X-License-Status') === 'EXPIRES_SOON') {
      toastStore.trigger({
        message: `Your license ${productString}will expire in the next 60 days.${expiryString} Please contact Hirundo to renew your license.`,
        background: 'variant-filled-warning',
      })
    }
  }
  if (!response.ok) {
    if (response.status === 401) {
      let jsonResponse
      try {
        jsonResponse = await response.json()
      } catch (err) {
        console.error('Request failed!', host, path, err)
      }
      if (jsonResponse?.message === 'The CSRF signatures submitted do not match.') {
        console.error('CSRF token mismatch, reloading')
        lastCsrfToken = ''
        return handleFetch(path, options, host, toastStore, retry + 1)
      }
      console.error('Request returned 401, logging user out', host, path)
      localStorage.setItem('authUser', '')
      loggedInStore.update(User.logout())
      await goto('/login', { invalidateAll: true })
      return response
    }
    let message = ''
    try {
      const responseJson = await response.json()
      message = responseJson.reason ?? responseJson.detail
    } catch (err) {
      try {
        message = await response.text()
      } catch (err) {
        console.error('Request failed!', host, path, err)
        throw new FetchError(response.status, response.statusText)
      }
    }
    throw new FetchError(response.status, message)
  }
  return response
}

export const HIRUNDO_EMAIL_ENDING = '@hirundo.io'
export const isHirundoEmail = (email: string | undefined): boolean =>
  email?.toLowerCase()?.endsWith(HIRUNDO_EMAIL_ENDING) ?? false

// Check loading or error state before accessing data
export const checkAsyncState = (asyncState: { loading: boolean; error: string }) => {
  if (asyncState.loading) {
    return 'Loading...'
  } else if (asyncState.error) {
    return `Error: ${asyncState.error}`
  }
  return null // When the data is loaded, return null so that content can be displayed
}

export const NAME_PATTERN = /^[a-zA-Z0-9\-_]+$/

export const canModify = (creatorId: number): boolean => {
  const { userId, primaryRole } = get(loggedInStore)
  if (primaryRole === Role.ADMIN || primaryRole === Role.OWNER) {
    return true
  }
  return creatorId === userId
}

export const ifUnauthorizedActionsMsg = (creatorId: number) => {
  if (!canModify(creatorId)) {
    return 'Unauthorized actions'
  }
}

export const getUnauthorizedActionsMsg: (id: number) => PopupSettings = (rowId) => ({
  event: 'hover',
  target: `unauthorizedActionsMsg-${rowId}`,
  placement: 'top',
})

export const loadData = async () => {
  const promises = []

  if (get(optimizationDatasetStore).size === 0) promises.push(loadOptimizationDatasets())
  if (get(storageIntegrationStore).size === 0) promises.push(loadStorageIntegrations())
  if (get(organizationsStore).size === 0) promises.push(loadOrganizations())
  if (get(usersStore).length === 0 || get(loggedInStore).userId === 0) promises.push(loadUsers())

  await Promise.all(promises)
}

export const enum ClassesOption {
  AutoDetection = '1',
  ManualInput = '2',
}
